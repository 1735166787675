import * as Yup from "yup";

export const numberPercentValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(0, "Число не должно быть меньше 0")
  .max(100, "Число не должно быть больше 100");

export const booleanNumberValidation = Yup.number()
  .required("Обязательное поле")
  .min(0, "Значение не может быть меньше 0")
  .max(1, "Значение не может быть больше 1");

export const numberValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(0, "Число не может быть меньше 0")
  .max(9999999999, "Число не может быть больше 9999999999");

export const offerExpirationValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(10, "Время не может быть меньше 10 сек")
  .max(30, "Время не может быть больше 30 сек");

export const intervalValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(30, "Интервал между предложениями не должен быть меньше 30 сек")
  .max(300, "Интервал между предложениями не должен быть больше 300 сек");

export const quantityLimitValidation = Yup.number()
  .required("Обязательное поле")
  .transform((value) => (Number.isNaN(value) ? null : value))
  .min(1, "Лимит по количеству предложении не должен быть меньше 1")
  .max(20, "Лимит по количеству предложении не должен быть больше 20");

export const textValidation = Yup.string().required("Обязательное поле");

export const getDynamicValidationSchema = (name: keyof ConfigsType) => {
  return Yup.object().shape({
    [name]: Yup.number().required("Обязательное поле"),
  });
};

export const getDynamicValidationArraySchema = (
  names: Array<keyof ConfigsType>,
) => {
  return Yup.object().shape(
    names.reduce((acc: any, name) => {
      acc[name] = Yup.number().required("Обязательное поле");
      return acc;
    }, {}),
  );
};

export type CommissionType = {
  id: number;
  city_id: number;
  base_commission: number;
  park_commission: number;
  bank_commission: number;
  bank_one_commission: number;
  bank_two_commission: number;
  bank_commission_per_withdrawal: number;
  withdraw_min_amount: number;
  daily_withdraw_count_limit: number;
  daily_withdraw_sum_limit: number;
  bank_one_commission_per_withdrawal: number;
  bank_one_withdraw_fee: number;
  bank_one_name_for_withdraw_commission: string;
  bank_one_min_commission: number;
  bank_three_withdraw_fee: number;
  bank_three_commission_per_withdrawal: number;
  bank_three_name_for_withdraw_commission: string;
  bank_three_min_commission: number;
  bank_four_withdraw_fee: number;
  bank_four_commission_per_withdrawal: number;
  bank_four_name_for_withdraw_commission: string;
  bank_four_min_commission: number;
};

export type ParkSettingsType = {
  use_city_configs: number;
  base_commission_per_ride: number;
  park_commission_per_ride: number;
  bank_one_commission_per_ride: number;
  bank_two_commission_per_ride: number;
  bank_one_fixed_withdraw_fee: number;
  bank_commission_per_ride: number;
  bank_commission_per_withdrawal: number;
  daily_withdraw_count_limit: number;
  withdraw_min_amount: number;
  daily_withdraw_sum_limit: number;
  bank_one_name_for_withdraw_commission: string;
  bank_three_name_for_withdraw_commission: string;
  bank_one_withdraw_fee: number;
  bank_three_withdraw_fee: number;
  bank_one_commission_per_withdrawal: number;
  bank_three_commission_per_withdrawal: number;
  bank_one_min_commission: number;
  bank_three_min_commission: number;
};

export type initialParams = Partial<ConfigsType> &
  Partial<ParkSettingsType> & { fees: FeesConfigType[] };

export type ConfigsType = {
  offer_expiration: number;
  offer_iter_limit: number;
  offer_iter_limit_timeout: number;
  to_home_limitation_per_day: number;
  on_business_limitation_per_day: number;
  change_home_points_limitation: number;
  change_home_points_days_limitation_in_days: number;
  to_home_search_radius: number;
  to_business_search_radius: number;
  regular_rides_search_radius: number;
  rides_by_finish_search_radius: number;
  zero_commission_enabled: number;
  zero_commission_minimum: number;
  show_ride_points_in_driver_offer: number;
  create_ride: number;
  create_ride_by_visa: number;
  save_card_visa: number;
  withdraw_funds_by_driver: number;
  distance_to_point_to_visit: number;
  show_ride_estimation_in_driver_offer: number;
  driver_ride_ignore_limit_enabled: number;
  driver_ride_ignore_limit_number: number;
  driver_ride_ignore_block_minutes: number;
  ya_prices_enabled: number;
  daily_withdraw_count_limit: number;
  withdraw_min_amount: number;
  daily_withdraw_sum_limit: number;
  create_ride_by_kaspi: number;
  create_ride_by_mbank: number;
  create_ride_by_family_account: number;
  create_ride_by_corporate_partner: number;
  withdraw_driver_funds_by_freedom: number;
  withdraw_driver_funds_by_epay: number;
  withdraw_driver_funds_by_altyn: number;
  replenish_driver_funds_by_kaspi: number;
  perDay: number;

  surge_pricing_enabled: number;
  use_jorgo: number;
  jorgo_redirect_timeout_seconds: number;
  adjust_ya_prices: number;
  round_ya_prices: number;

  // TODO: remove when motor will get new configs
  use_jorgo_api: number;
  redirect_to_jorgo_seconds: number;
};

export type FeesCommissionType = "driver_withdraw" | "ride_payment";

export type FeesPartnerType = "system" | "park" | "mbank" | "payler";

export type FeesConfigType = {
  id: number;
  partner_name: string;
  partner_id: FeesPartnerType;
  operation_title: string;
  type: FeesCommissionType;
  fixed_fee: number;
  min_fee: number;
  percentage: number;
};

export type FeesType = {
  id: number;
  city_id: number;
  park_id: number;
  configs: FeesConfigType[];
};

export type Params<T> = {
  disabled?: boolean;
  name: string;
  field: keyof T | null;
};

export type UpdateCityConfigQueryParams = {
  cityId: string;
  configs: Partial<ConfigsType>;
};

export type UpdateParkConfigQueryParams = {
  parkId: string;
  configs: Partial<ParkSettingsType>;
};
