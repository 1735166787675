import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services/reports";
import { QueryParams } from "@common/Types";

export const useGetAccountReports = (params: QueryParams) => {
  return useQuery({
    queryKey: [QUERY_KEYS.ACCOUNT_REPORTS, params],
    queryFn: async () => await reportsService.getAccountReports(params),
  });
};
