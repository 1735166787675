import { useMutation, useQueryClient } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";
import { NewsFormType } from "@common/Types";

export const useEditNews = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data, type }: { data: NewsFormType; type: string }) =>
      newsService.editNews(data, type),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
    },
  });
};
