import { CaretDown } from "phosphor-react";
import React from "react";

import { useGetParksShort } from "@api/queries";
import { CarIcon } from "@assets/Icons";
import { AvailableFilterKeys, PartialFiltersType } from "@common/Types";
import { buildParksShortFilterOptions } from "@common/Utils";

import { CheckboxList } from "../checkbox-list";

type Props = {
  filters: PartialFiltersType;
  setFilters: (values: PartialFiltersType) => void;
  filterName: AvailableFilterKeys;
};

export const ParksFilter = ({ filters, setFilters, filterName }: Props) => {
  const selectedCity = filters.city_id;

  const { data: parkList } = useGetParksShort(selectedCity);

  const getParks = () => {
    return parkList ? buildParksShortFilterOptions(parkList) : [];
  };

  return (
    <CheckboxList
      MainIcon={<CarIcon />}
      icon={CaretDown}
      filters={filters}
      setFilters={setFilters}
      title={"Таксопарк"}
      field={filterName}
      options={getParks()}
    />
  );
};
