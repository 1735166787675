import { Textarea } from "@mantine/core";
import { getHotkeyHandler } from "@mantine/hooks";
import clsx from "clsx";
import queryString from "query-string";
import React, { useEffect, useState } from "react";

import {
  useGetDriversMessagesById,
  useGetRidersMessagesById,
} from "@api/queries";
import { ArrowIcon, MessageIcon } from "@assets/Icons";
import { ROUTES, WS_TYPE } from "@common/Constants";
import {
  useDimension,
  useNavigateTo,
  useSelectedChat,
  useSocket,
} from "@common/Hooks";
import {
  AdminMessageType,
  ChatMessagesFilterTypes,
  ChatMessageType,
  Queries,
  WsMessage,
} from "@common/Types";
import {
  Button,
  Col,
  Loader,
  LoadingIcon,
  Row,
  Typography,
} from "@components/Elements";
import { InfiniteScrollComponent, NoResult } from "@components/Shared";

import { MessageCardImage } from "./message-card-image";
import { MessageCardText } from "./message-card-text";

type Props = {
  type: "drivers" | "riders";
};

const MESSAGE_LIMIT = 2048;

const initialMessage: AdminMessageType = {
  type: "",
  data: {
    message: "",
  },
};

export const DialogWindow = ({ type }: Props) => {
  const isRiders = type === "riders";
  const isDrivers = type === "drivers";
  const queries: Queries = queryString.parse(window.location.search);
  const query = (isDrivers ? queries.drivers : queries.riders) || "";

  // hooks
  const { isDesktop } = useDimension();
  const { navigateTo } = useNavigateTo();
  const { selectedChat, setSelectedChat } = useSelectedChat();
  const { observer, sendMessage: sendWsMessage, online } = useSocket();

  const chatId = selectedChat?.id?.toString() || "";

  // states
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<ChatMessagesFilterTypes>({
    search: "",
    skip: 0,
  });
  const [message, setMessage] = useState<AdminMessageType>(initialMessage);
  const [newMessages, setNewMessages] = useState<WsMessage | null>(null);
  const [messages, setMessages] = useState<ChatMessageType[]>([]);

  const {
    data: riderDialog,
    refetch: refetchRiderDialog,
    isFetched: isFetchedRiderMessages,
    isError: isErrorRiderMessages,
  } = useGetRidersMessagesById(chatId, {
    enabled: isRiders,
    ...filters,
  });

  const {
    data: driverDialog,
    refetch: refetchDriverDialog,
    isFetched: isFetchedDriverMessages,
    isError: isErrorDriverMessages,
  } = useGetDriversMessagesById(chatId, {
    enabled: isDrivers,
    ...filters,
  });

  // variables
  const dialog = isDrivers ? driverDialog : riderDialog;
  const refetchMessages = isDrivers ? refetchDriverDialog : refetchRiderDialog;

  // Check if the component is disabled
  const isDisabled = !online || !selectedChat?.phone || !dialog;

  // Determine error and fetched states
  const isError = isErrorDriverMessages || isErrorRiderMessages;
  const isFetched = isFetchedDriverMessages || isFetchedRiderMessages;

  // Check if there are no results
  const noResult =
    (isFetched && (!dialog || !selectedChat?.phone)) || query === "null";

  useEffect(() => {
    if (newMessages) {
      setMessages((prevValues) => [...prevValues, newMessages.data]);
    }
  }, [newMessages]);

  useEffect(() => {
    const handleWsMessage = (message: WsMessage) => {
      setNewMessages(message);
    };

    observer.on("ws-message", handleWsMessage);
    return () => {
      observer.off("ws-message", handleWsMessage);
    };
  }, [observer]);

  useEffect(() => {
    if (selectedChat?.phone) {
      refetchMessages();
      setLoading(true);
      setMessage(initialMessage);
      setFilters((prev) => ({ ...prev, limit: 16 }));
    }
  }, [selectedChat]);

  useEffect(() => {
    if (dialog?.items) {
      setLoading(false);
      setMessages(dialog.items);
    }
  }, [dialog]);

  useEffect(() => {
    if (isError) {
      setLoading(false);
    }
  }, [isError]);

  const handleSendMessage = () => {
    if (
      online &&
      message !== initialMessage &&
      message.data.message.length < MESSAGE_LIMIT &&
      sendWsMessage
    ) {
      sendWsMessage(JSON.stringify(message));
      setMessage(initialMessage);
    }
  };

  const handleRedirectToClient = (id?: number) => {
    navigateTo(`${isDrivers ? ROUTES.DRIVERS : ROUTES.RIDERS}/${id}`, {
      _blank: true,
    });
  };

  const getMessageComponent = (
    message: ChatMessageType,
    prevMessageSender?: string,
  ) => {
    switch (message.type) {
      case "text":
        return (
          <MessageCardText
            key={message.id}
            message={message}
            prevMessageSender={prevMessageSender}
          />
        );
      case "image":
        return <MessageCardImage key={message.id} message={message} />;
      default:
        return (
          <MessageCardText
            key={message.id}
            message={message}
            prevMessageSender={prevMessageSender}
          />
        );
    }
  };

  const handleMessageChange = (value: string) => {
    const validateInput = () => {
      return value.length === 1 && value === " " ? false : true;
    };

    if (selectedChat?.phone && validateInput()) {
      const { member_id } = selectedChat;
      const wsType = WS_TYPE[type.toUpperCase() as keyof typeof WS_TYPE];
      const messageData = {
        message: value,
        ...(isDrivers ? { driver_id: member_id } : { rider_id: member_id }),
      };

      setMessage({
        type: wsType,
        data: messageData,
      });
    }
  };

  const handleRefetch = () => {
    setFilters((prev) => ({
      ...prev,
      limit: prev.limit ? prev.limit + 8 : 16,
    }));
  };

  return (
    <Col
      className={clsx(
        "w-full rounded-[8px] overflow-hidden h-[calc(100vh-206px)] max-h-[960px] bg-chat-bg shadow-chat border-neutral-text-100 transition-all duration-450 relative",
        {
          "max-w-full": !isDesktop && selectedChat,
          "max-w-[0]": !isDesktop && !selectedChat,
        },
      )}
    >
      <Row className="w-full h-[64px] px-[16px] py-[10px] gap-[4px] cursor-pointer border-solid border-b-[1px] border-neutral-text-100">
        <Col
          className="w-full"
          onClick={() => handleRedirectToClient(selectedChat?.member_id)}
        >
          <Typography
            as="h4"
            variant="body-1-b"
            color="neutral-800"
            className="font-bold flex flex-row items-center gap-[10px]"
          >
            {selectedChat?.first_name || selectedChat?.last_name
              ? `${selectedChat?.first_name} ${selectedChat?.last_name}`
              : "Не указано"}
            <ArrowIcon rotation="leftTop" />
          </Typography>
          <Typography variant="caption-a" color="neutral-500">
            {selectedChat?.phone}
          </Typography>
        </Col>
        <Button
          size="md"
          view="green"
          className="self-end md:hidden"
          onClick={() => setSelectedChat(null)}
        >
          Назад
        </Button>
      </Row>
      <Loader className="w-full h-full" loading={loading}>
        {noResult ? (
          <NoResult className="w-full h-full" />
        ) : (
          <InfiniteScrollComponent
            reversed
            data={messages}
            fetchItems={handleRefetch}
            hasMore={dialog?.length !== messages?.length}
            loaderComponent={selectedChat ? <LoadingIcon /> : null}
            ItemComponent={({ dataItem, prevDataItem }) =>
              getMessageComponent(dataItem, prevDataItem?.sender)
            }
            className="w-full bg-chat-bg h-full p-[18px]"
          />
        )}
      </Loader>

      <Row className="w-full py-[12px] px-[16px] gap-[8px] border-t-[1px] border-solid border-neutral-border-100">
        <Textarea
          disabled={isDisabled}
          rows={2}
          maxRows={6}
          maxLength={MESSAGE_LIMIT + 1}
          error={
            message.data.message.length > MESSAGE_LIMIT &&
            "превышен лимит символов"
          }
          autosize
          autoFocus={isDesktop}
          value={message.data.message}
          onKeyDown={getHotkeyHandler([["Enter", handleSendMessage]])}
          classNames={{
            root: "p-0 w-full",
            input:
              "px-[14px] py-[8px] bg-neutral-bg-75 focus:bg-neutral-bg-0 border-transparent focus:border-1 focus:border-brand-border-500 outline-none shadow-none",
          }}
          placeholder="Введите сообщение"
          onChange={(event) => handleMessageChange(event.target.value)}
        />
        <Button
          disabled={isDisabled}
          onClick={handleSendMessage}
          className={clsx("bg-brand-bg-200 p-[10px] rounded-md", {
            "bg-neutral-bg-100": isDisabled,
          })}
        >
          <MessageIcon color={isDisabled ? "#BBBABE" : ""} />
        </Button>
      </Row>
    </Col>
  );
};
