import { LocalFiltersType } from "@common/Types";

export const removePlusFromPlaceholder = (placeholder: string) => {
  if (placeholder.charAt(0) === "+") {
    return placeholder.substring(1);
  }
  return placeholder;
};

export function parseFilenameFromContentDisposition(
  contentDisposition: string | null,
  defaultFilename: string,
): string {
  if (contentDisposition) {
    const matches = contentDisposition.match(/filename="(.+?)"/);
    if (matches && matches.length === 2) {
      return matches[1];
    }
  }
  return defaultFilename;
}

export const enumToLabelValueArray = (
  enumObj: Record<string, string>,
): LocalFiltersType[] => {
  return Object.keys(enumObj).map((key) => ({
    label: enumObj[key],
    value: key,
  }));
};
