import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { QUERY_KEYS } from "@api/config";
import { promotionsService } from "@api/services";
import { PromotionRideParticipantsType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useGetRidePromotionParticipants = (promotionId: string) => {
  const { data, ...query } = useQuery<PromotionRideParticipantsType>({
    queryKey: [QUERY_KEYS.RIDE_PROMOTION_PARTICIPANTS, promotionId],
    queryFn: async () =>
      await promotionsService.getRidePromotionParticipants(promotionId),
  });

  return { response: data, ...query };
};
