// Motor
import { isTargetNavi } from "@assets/Flags";

export const MOTOR_CURRENCY_SYMBOL = "⍑";

// Navi
export const NAVI_CURRENCY_SYMBOL = "сом";

export const CURRENCY_SYMBOL = isTargetNavi
  ? NAVI_CURRENCY_SYMBOL
  : MOTOR_CURRENCY_SYMBOL;

export const MOBILE_WIDTH = 640;
export const DESKTOP_START_WIDTH = 768;
export const CURRENCY_CODE = "KZT";
export const COUNTRY_CODE = "KAZ";
export const CURRENCY = "тенге";
export const MAIN_LANG = "ru";
export const MAIN_LANG_LABEL = "рус";
export const ALTERNATIVE_LANG = "kz";
export const ALTERNATIVE_LANG_LABEL = "каз";

export const INITIAL_MAP_VIEWPORT = {
  latitude: 46.3571844,
  longitude: 71.3123864,
};

export const MOTOR_ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.kz";
export const MOTOR_IOS_APP_LINK =
  "https://apps.apple.com/kg/app/motor-taxi/id6450346747";
export const MOTOR_DRIVER_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.motor";

export const NAVI_ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=reviro.taxi.rider&hl=ru&gl=US";
export const NAVI_IOS_APP_LINK =
  "https://apps.apple.com/kg/app/navi/id1585148582";
