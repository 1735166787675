import React from "react";

import { ROLES_NAVI } from "@common/Constants";
import { FilterComponentPropsType, FilterObjectType } from "@common/Types";
import { formatDate } from "@common/Utils";
import {
  AudienceFilter,
  AuthRender,
  CitiesFilterMobile,
  DateRangeFilter,
  FraudControlTypeFilters,
  LanguageFilterMobile,
  SubscribersFilter,
} from "@components/Shared";

import { StatusControlTypeFilters } from "./application-status";

export const filtersSchema: FilterObjectType = {
  city_id: {
    component: (props: FilterComponentPropsType) => (
      <AuthRender roles={[ROLES_NAVI.SUPERADMIN]}>
        <CitiesFilterMobile {...props} />
      </AuthRender>
    ),
    initialValue: undefined,
  },
  fraud_status: {
    component: (props: FilterComponentPropsType) => (
      <FraudControlTypeFilters {...props} />
    ),
  },
  application_status: {
    component: (props: FilterComponentPropsType) => (
      <StatusControlTypeFilters {...props} />
    ),
  },
  landing_application_status: {
    component: (props: FilterComponentPropsType) => (
      <StatusControlTypeFilters {...props} />
    ),
  },
  date_range: {
    component: (props: FilterComponentPropsType) => (
      <DateRangeFilter {...props} />
    ),
    initialValue: [
      formatDate(new Date()) as string,
      formatDate(new Date()) as string,
    ],
  },
  audience: {
    component: (props: FilterComponentPropsType) => (
      <AudienceFilter {...props} />
    ),
  },
  subscribers: {
    component: (props: FilterComponentPropsType) => (
      <SubscribersFilter {...props} />
    ),
    initialValue: "all",
  },
  language: {
    component: (props: FilterComponentPropsType) => (
      <LanguageFilterMobile {...props} />
    ),
    initialValue: undefined,
  },
};
