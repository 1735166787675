import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { newsService } from "@api/services";
import { NewsDetailType } from "@common/Types";

export const useGetNewsDetail = (id: number, type: string) => {
  return useQuery<NewsDetailType>({
    queryKey: [QUERY_KEYS.NEWS_DETAILS, id, type],
    queryFn: async () => await newsService.getNewsDetail(id, type),
  });
};
