import { DatePicker as MantinePicker } from '@mantine/dates';
import { format } from "date-fns";
import { useField, useFormikContext } from "formik";
import React from "react";

type MantineProps = {
  name: string;
  error: string | undefined;
  touched: boolean | undefined;
  defaultDate?: Date | string | null;
};

export const DatePicker: React.FC<MantineProps> = ({ error, touched, defaultDate, ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <MantinePicker
      zIndex={1000}
      {...props}
      {...field}
      locale="ru"
      allowFreeInput={true}
      defaultValue={defaultDate ? new Date(defaultDate) : null}
      clearable={false}
      inputFormat="YYYY-MM-DD"
      labelFormat="YYYY-MM-DD"
      icon={
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.75 1.875H11.875V0.625H10.625V1.875H4.375V0.625H3.125V1.875H1.25001V14.375H13.75V1.875ZM12.5 13.125H2.5V4.99998H12.5V13.125Z"
            fill="#5E656E"
          />
        </svg>
      }
      onChange={(val: Date) => {
        if (val) setFieldValue(field.name, format(val, "yyyy-MM-dd"));
      }
      }
      error={error && touched ? <p className="text-red-500 text-sm">{error}</p> : null}
    />
  )
}

