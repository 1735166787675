import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services/reports";
import { PartialFiltersType } from "@common/Types";

export const useGetPaymentReports = (filters: PartialFiltersType) => {
  return useQuery({
    queryKey: [QUERY_KEYS.PAYMENTS, filters],
    queryFn: async () => await reportsService.getPaymentReports(filters),
    refetchInterval: 15000,
  });
};
