import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "@api/config";
import { tariffsService } from "@api/services";
import { TariffType } from "@common/Types";

export const useGetTariffsById = (city_id: string | number) => {
  return useQuery<TariffType[]>({
    queryKey: [QUERY_KEYS.TARIFFS_BY_ID, city_id],
    queryFn: async () => await tariffsService.getTariffsById(city_id),
  });
};
