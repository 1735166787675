import React from "react";

import { NetworkErrorIcon } from "@assets/Icons";
import { Col, Row, Typography } from "@components/Elements";

export const NetworkErrorCard = () => {
  return (
    <Row className="mx-[12px] my-[8px] p-[12px] rounded-[8px]  border-[1px]">
      <Row className="gap-[16px] border-neutral-border-100">
        <NetworkErrorIcon />
        <Col
          className="w-full flex-nowrap gap-[4px]"
          justifyContent="justify-between"
        >
          <Typography
            variant="body-2-b"
            color="neutral-800"
            className="font-bold"
          >
            Отсутствует соединение
          </Typography>
          <Typography variant="caption-a" color="neutral-500">
            убедитесь, что ваш компьютер имеет активное подключение к Интернету
          </Typography>
        </Col>
      </Row>
    </Row>
  );
};
