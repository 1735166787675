import { Modal } from "@mantine/core";
import { clsx } from "clsx";
import { isToday } from "date-fns";
import React, { FC } from "react";

import { ChatMessageType } from "@common/Types";
import { formatDate } from "@common/Utils";
import { Col, LazyImage, Typography } from "@components/Elements";

type Props = {
  message: ChatMessageType;
};

export const MessageCardImage: FC<Props> = React.memo(({ message }) => {
  const [opened, setOpened] = React.useState(false);

  const dateFormat = isToday(new Date(message.created_at))
    ? "HH:mm"
    : "MM-dd-yyyy HH:mm";

  return (
    <Col
      className={clsx("inline-block mt-[4px] mb-[4px]", {
        "self-end": message.sender === "admin",
        "self-start": message.sender !== "admin",
      })}
    >
      <Col className="mb-[4px]">
        <LazyImage
          className="flex flex-col w-[380px] h-[500px] cursor-pointer rounded-[20px]"
          src={message?.image_url || ""}
          alt={message?.sender}
          onClick={() => setOpened(true)}
        />
        <Modal
          size="96%"
          opened={opened}
          onClose={() => setOpened(false)}
          withinPortal
          withCloseButton
          closeOnEscape
          closeOnClickOutside
          overflow="inside"
          centered
          classNames={{
            modal: "h-full",
            body: "h-full w-full overflow-hidden",
          }}
        >
          <LazyImage
            className="w-[100%] h-[100%] object-contain"
            src={message?.image_url || ""}
            alt={message?.sender}
            onClick={() => setOpened(true)}
          />
        </Modal>
      </Col>
      <Typography className="text-sm" color="neutral-500">
        {formatDate(message.created_at, dateFormat)}
      </Typography>
    </Col>
  );
});
