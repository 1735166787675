import { Field } from "formik";
import React from "react";

import { LocalFiltersType } from "@common/Types";

type Props = {
  label: string;
  field: string;
  array: LocalFiltersType[] | [];
  error?: string | string[];
  touched?: boolean | undefined;
};

export const FormCheckBox: React.FC<Props> = ({
  label,
  field,
  array,
  error,
  touched,
}) => {
  return (
    <div className="flex flex-col items-start">
      <label className="text-gray-600 text-sm">{label}</label>
      <div className="flex items-center flex-wrap">
        {array &&
          array.map((tariff) => (
            <label
              className="mr-4 flex items-center cursor-pointer"
              key={tariff.value}
            >
              <Field
                type="checkbox"
                name={field}
                value={tariff.value}
                className="mr-2 cursor-pointer rounded outline-none focus:ring-1 focus:ring-blue-600 focus:ring-offset-0"
              />
              {tariff.label}
            </label>
          ))}
      </div>
      {error && touched && <p className="text-red-600 text-sm mt-1">{error}</p>}
    </div>
  );
};
