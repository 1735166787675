import { appConfig } from "@api/config";
import { isTargetNavi } from "@assets/Flags";

/**
 * Сервисы для permission
 */

const src = appConfig.api.dashboard;

export const permissionsPath = {
  permission: isTargetNavi
    ? `${src}/dispatcher/permission/`
    : `${src}/admin_navi/permission/`,
};
