import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { reportsService } from "@api/services/reports";
import { AccountReportFormValues } from "@common/Types";

export const useCreateAccountReport = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (report: AccountReportFormValues) =>
      reportsService.createAccountReport(report),

    onSuccess: () => {
      toast.success(`Создание отчёта успешно принято в обработку`);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ACCOUNT_REPORTS] });
    },
  });
};
