import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";

import { QUERY_KEYS } from "@api/config";
import { driversService } from "@api/services";
import { LandingDriverApplicationType } from "@common/Types";
import { showErrorToast } from "@common/Utils";

export const useCreateDriverLandingApplication = () => {
  const { data, ...query } = useMutation({
    mutationKey: [QUERY_KEYS.LANDING_DRIVER_APPLICATION],
    mutationFn: async (application: LandingDriverApplicationType) => {
      return await driversService.sendDriverLandingApplication(application);
    },
    onSuccess: () => {
      toast.success("Ваша заявка успешно доставлена!", { duration: 2000 });
    },
    onError: (error) => {
      showErrorToast(error as AxiosError);
    },
    gcTime: 0,
    retry: 0,
  });

  const response = data?.data;

  return { response, ...query };
};
