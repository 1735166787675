import clsx from "clsx";
import queryString from "query-string";
import React, { useEffect, useState } from "react";

import {
  useGetDriversChatList,
  useGetDriversChatListDetail,
  useGetRidersChatList,
  useGetRidersChatListDetail,
} from "@api/queries";
import { ROLES, ROUTES } from "@common/Constants";
import {
  useDelay,
  useDimension,
  useNavigateTo,
  useSelectedChat,
  useSocket,
} from "@common/Hooks";
import {
  ChatListItemTypes,
  ChatMessagesFilterTypes,
  Queries,
} from "@common/Types";
import { Col, Flexbox, Loader, LoadingIcon, Row } from "@components/Elements";
import {
  AuthRender,
  CitiesFilter,
  InfiniteScrollComponent,
  NoResult,
} from "@components/Shared";

import { ChatSearch } from "../elements/chatSearch";

import { DialogWindow } from "./dialog-window";
import { MemberComponentItem } from "./member-component-Item";
import { NetworkErrorCard } from "./network-error-card";

type Props = {
  type: "drivers" | "riders";
};

export const MemberList = ({ type }: Props) => {
  const isRiders = type === "riders";
  const isDrivers = type === "drivers";

  const queries: Queries = queryString.parse(window.location.search);
  const query = (isDrivers ? queries.drivers : queries.riders) || "";

  // hooks
  const { isDesktop } = useDimension();
  const { navigateTo } = useNavigateTo();
  const { selectedChat, setSelectedChat } = useSelectedChat();
  const { observer, online } = useSocket();

  // states
  const [filters, setFilters] = useState<ChatMessagesFilterTypes>({
    search: "",
    skip: 0,
  });

  // queries
  const { data: riderChat } = useGetRidersChatListDetail({
    enabled: !selectedChat && !!query && isRiders,
    search: query,
    limit: 1,
  });
  const { data: driverChat } = useGetDriversChatListDetail({
    enabled: !selectedChat && !!query && isDrivers,
    search: query,
    limit: 1,
  });

  // list
  const {
    data: riderChatList,
    isLoading: isLoadingRiderData,
    isFetching: isFetchingRiders,
    refetch: refetchRidersList,
  } = useGetRidersChatList({
    enabled: !!(isRiders && filters.limit),
    ...filters,
  });
  const {
    data: driverChatList,
    isLoading: isLoadingDriverData,
    isFetching: isFetchingDrivers,
    refetch: refetchDriversList,
  } = useGetDriversChatList({
    enabled: !!(isDrivers && filters.limit),
    ...filters,
  });

  // variables
  const list = isDrivers ? driverChatList : riderChatList;
  const refetchList = isDrivers ? refetchDriversList : refetchRidersList;
  const selectedChatData = isDrivers ? driverChat : riderChat;

  const isLoading = isDrivers ? isLoadingDriverData : isLoadingRiderData;
  const isFetching = isDrivers ? isFetchingDrivers : isFetchingRiders;

  const isChatsExist = list && list.length > 0;
  const noResult = !!(!isLoading && !isChatsExist && filters.limit);

  const refetch = useDelay(refetchList, 350);

  useEffect(() => {
    if (selectedChat) {
      handleRedirectURLQuery(selectedChat.phone);
    }
  }, [selectedChat]);

  useEffect(() => {
    if (isDesktop && !selectedChat && list) {
      if (!query) {
        const item = list.items[0];
        handleSelectChat(item);
      } else if (selectedChatData?.items) {
        const item = selectedChatData.items[0];
        handleSelectChat(item);
      }
    }
  }, [selectedChatData, isDesktop, selectedChat, list?.length, query]);

  useEffect(() => {
    if (selectedChat && !selectedChat.is_read_admin) {
      refetch();
    }
  }, [selectedChat]);

  useEffect(() => {
    const onMessageHandler = () => {
      refetch();
    };

    observer.on("ws-message", onMessageHandler);

    return () => {
      observer.off("ws-message", onMessageHandler);
    };
  }, []);

  const handleRedirectURLQuery = (phone: string) => {
    navigateTo(`${ROUTES.CHAT}?${type}=${phone}`, {
      replace: true,
    });
  };

  const handleRefetch = () => {
    setFilters((prev) => ({
      ...prev,
      limit: prev.limit ? prev.limit + 8 : 16,
    }));
  };

  const handleSelectChat = async (dialog: ChatListItemTypes) => {
    if (dialog?.id !== selectedChat?.id) {
      const memberId = isDrivers ? dialog.driver_id : dialog.rider_id;

      setSelectedChat({
        id: dialog.id,
        phone: dialog.phone,
        first_name: dialog.first_name,
        last_name: dialog.last_name,
        is_read_admin: dialog.is_read_admin,
        member_id: memberId,
      });
    }
  };

  return (
    <Flexbox className={clsx("w-full mt-[16px]", { "gap-[16px]": isDesktop })}>
      <Col
        className={clsx(
          "w-full h-[calc(100vh-206px)] max-h-[960px] rounded-[8px] bg-chat-bg shadow-chat border-neutral-text-100 overflow-hidden transition-all duration-450",
          {
            "max-w-[350px]": isDesktop,
            "max-w-[0]": !isDesktop && selectedChat,
            "max-w-full": !isDesktop && !selectedChat,
          },
        )}
      >
        <Row
          alignItems="items-center"
          justifyContent="justify-center"
          className="w-full h-[64px] p-[12px] gap-[8px] border-solid border-b-[1px] border-neutral-text-100"
        >
          <AuthRender roles={[ROLES.SUPERADMIN]}>
            <CitiesFilter
              noTitle
              className="h-[40px]"
              filters={filters}
              setFilters={setFilters}
            />
          </AuthRender>
          <ChatSearch filters={filters} setFilters={setFilters} />
        </Row>
        {!online && <NetworkErrorCard />}

        <Loader loading={isLoading}>
          <InfiniteScrollComponent
            hidden={noResult}
            data={list?.items || []}
            isLoading={isFetching}
            hasMore={list?.length !== list?.items.length}
            fetchItems={handleRefetch}
            loaderComponent={<LoadingIcon />}
            ItemComponent={({ dataItem }) => (
              <MemberComponentItem
                data={dataItem}
                curItem={selectedChat}
                handleSelectChat={handleSelectChat}
              />
            )}
          />
          {noResult && <NoResult />}
        </Loader>
      </Col>
      <DialogWindow type={type} />
    </Flexbox>
  );
};
