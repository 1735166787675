import { Field } from "formik";
import React, { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";

import { LocalFiltersType } from "@common/Types";
import { Col } from "@components/Elements";

type Props = {
  label: string | JSX.Element;
  field: string;
  value?: string | number | null;
  array: LocalFiltersType[];
  errors: string | undefined | string[];
  touched: boolean | undefined;
  placeholder: string;
  labelVisible: string;
  errorClassName?: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  readOnly?: boolean;
};

export const SelectField: React.FC<Props> = ({
  label,
  field,
  value,
  array,
  errors,
  touched,
  placeholder,
  labelVisible,
  errorClassName,
  onChange,
  readOnly,
}) => {
  return (
    <Col>
      <div className="flex items-center space-y-1 w-full">
        <label className={`w-40 pr-6 ${labelVisible || ""}`}>{label}</label>
        <Field
          as="select"
          name={field}
          className="h-[40px] px-4 py-0 border border-neutral-border-300 rounded-[4px] focus:outline-none focus:border-gray-400 transition-colors w-full appearance-none cursor-pointer"
          onChange={onChange}
          disabled={readOnly}
        >
          <option value="" defaultChecked>
            {placeholder}
          </option>
          {array &&
            array.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
        </Field>
      </div>
      {errors && touched && (
        <div
          className={twMerge(
            "flex justify-center text-sm text-red-500 mt-2",
            errorClassName,
          )}
        >
          {errors}
        </div>
      )}
    </Col>
  );
};
